
/* Media query for small screnn 1440 screen*/
@media only screen and (min-width: 1440px) and (max-width: 1440px) {
    .table-row {
        margin: 2% -2%;
    }
}
/* Media query for small screnn 1024 screen*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    .coin-icon {
        width: 38px;
        height: 38px;
    }
    .coin-info strong {
        font-size: 17px;
    }
    .coin-fullname {
        font-size: 14px;
    }
    .t_data {
        font-size: 14px;
    }
    .exchange-button .exchange-icon {
        width: 30px;
        height: 30px;
    }
    .exchange-button {
        padding: 10px 11px;
        width: 144px;
        height: 48px;
        border: 1px solid #100F0E;
        font-size: 14px;
    }
    .row.table-row {
        padding: 0% 2% 0% 0%;
    }
}
/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
    p.currencies_para {
        width: 65%;
        margin: 0px auto;
    }
    .table-header h4 {
        font-size: 18px;
    }
    .coin-icon {
        width: 32px;
        height: 32px;
    }
    .coin-info strong {
        font-size: 15px;
    }
    .coin-fullname {
        font-size: 13px;
    }
    .t_data {
        font-size: 12px;
    }    
    .exchange-button {
        padding: 0px 6px;
        border: none;
        border-radius: 1000px;
        color: #100F0E;
        cursor: pointer;
        transition: background-color 0.3s ease;
        display: flex;
        align-items: center;
        width: 115px;
        height: 43px;
        border: 1px solid #100F0E;
        font-size: 11px;
        font-weight: 800;
        line-height: 24px;
        font-family: basementgrotesque;
        background-color: transparent;
        margin: 0px 0px 0px -22px;
    }
    .exchange-button .exchange-icon {
        width: 25px;
        height: 25px;
        margin-right: 5px;
    }
    .table-row {
        margin: 2% -3% 0% 0%;
    }
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){

    .table-header {
        padding-bottom: 2%;
        border-bottom: 1px solid #C5C2BF;
        margin: 0% 2% 2% 2%;
    }
    .table-header h4 {
        font-size: 10px;
    }
    .search-box {
        font-size: 11.2px;
    }
    .coin-icon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
    .coin-fullname {
        font-size: 8px;
        line-height: 4.36px;
    }
    .coin-info strong {
        font-size: 8px;
    }
    .t_data {
        font-size: 8px;
    }
    .exchange-button {
        padding: 0px 6px;
        width: 76px;
        height: 16px;
        font-size: 8px;
        font-weight: 800;
        line-height: 24px;
        font-family: basementgrotesque;
        background-color: transparent;
        margin-left: 9px;
    }
    .exchange-button .exchange-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 1px;
    }
    .coin-info {
        padding: 0px;
        margin: 0px;
    }
    .price_info {
        color: #F34900;
        margin-left: -34px;
        padding-right: 53px;
    }
    .search-icon img {
        width: 12px;
        height: 12px;
        opacity: 0.7;
    }
    .search-label {
        top: -9px;
        left: 10px;
        font-size: 11px;
    }
    .table-row {
        margin: 2% 0%;
        padding: 0% 6% 0% 2%;
    }
    .col-3.t_data.volum_data {
        margin: 0px 4px 0px -6px;
    }
    .mobile_container {
        width: 100%;
        padding-left: 2px;
        padding-right: 2px;
        margin-left: auto;
        margin-right: auto;
    }
}
/* Media query for small mobile devices*/
@media only screen and (max-width: 375px){
    .table-header h4 {
        font-size: 8px;
    }
    .coin-icon {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }
    .coin-fullname {
        font-size: 8px;
        line-height: 0.36px;
    }
    .coin-info {
        padding: 0px;
        margin: 0px;
        display: flex !important;
        align-items: center !important;
    }
    .t_data {
        font-size: 7px;
    }
    .exchange-button {
        padding: 0px 6px;
        width: 65px;
        height: 16px;
        font-size: 6px;
        font-weight: 800;
        line-height: 24px;
        font-family: basementgrotesque;
        background-color: transparent;
        margin-left: 9px;
    }
    .table-row {
        margin: 2% 0%;
        padding: 0% 9% 0% 3%;
    }

}

