/* Media query for small screnn 1024 screen*/
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
    img.bitcoin_logo {
        width: 22%;
        height: auto;
    }
    img.ETH_logo {
        width: 22%;
        height: auto;
    }
    .exchange-partner-recipient-input {
        width: 270px;
        margin-left: 11px;
    }
    .exchange-partner-recipient-input> span {
        font-size: 16px;
    }
    .exchange-partner-recipient-input> img {
        width: 165px;
    }
    .exchange-type-recipient-input> span {
        font-size: 16px;
    }
    .exchange-type-recipient-input> div {
        width: 165px;
        font-size: 16px;
    }
    .exchange-type-recipient-input> div> img {
        width: 20px;
        margin-right: 5px;
    }
}
/* Media query for Tablet 768 screen*/
@media only screen and (min-width: 768px) and (max-width:1023px){
    .progress-div-create {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: auto;
        padding: 0% 9%;
    }
    .progress-div-create-status> :nth-child(1) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: 20px;
    }
    .progress-div-create-status> :nth-child(3) {
        color: white;
        font-weight: 700;
        margin-right: 140px;
    }
    img.Horizontal_line {
        display: none;
    }
    img.verticalLine {
        display: block !important;
        width: 1%;
    }
}
/* Media query for mobile devices*/
@media only screen and (max-width: 767px){
    .progress-div-create {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: auto;
        padding: 0% 10%;
    }
    .progress-div-create-status> :nth-child(1) {
        color: #f4a70c;
        font-weight: 700;
        margin-left: 17px;
        font-size: 12px;
    }
    .progress-div-create-status> :nth-child(2) {
        color: white;
        font-weight: 700;
        margin-left: -36px;
        font-size: 12px;
    }
    .progress-div-create-status> :nth-child(3) {
        color: white;
        font-weight: 700;
        margin-right: 475px;
        font-size: 12px;
    }
    .grey-line-div {
        width: 101px;
    }
    .exchange-partner-recipient-input {
        width: 200px;
        margin-left: 11px;
    }
    .exchange-partner-recipient-input> span {
        font-size: 16px;
    }
    .exchange-partner-recipient-input> img {
        width: 165px;
        margin-top: 15px;
    }
    .exchange-type-recipient-input> span {
        font-size: 16px;
    }
    .exchange-type-recipient-input> div {
        width: 165px;
    }    
    img.Horizontal_line {
        display: none;
    }
    img.verticalLine {
        display: block !important;
        width: 1%;
    }
}

@media only screen and (max-width: 430px){

    .recipient-input-label{
        font-size: 10px;
    }

    .progress-div-create-status> :nth-child(2){
        font-size: 10px;
        margin-left: -20px;
      }

      .progress-div-create-status> :nth-child(3){
        font-size: 10px;
        margin-right: -15px;
      }

    .progress-div-create-status> :nth-child(1){
        font-size: 10px;
        margin-left: -32px;
      }

    .recipient-send-coin-logos{
        width: 100px;
    }

    .progress-div-create-status{
        width: 300px;
    }

    .recipient-input-how-to{
        text-align: center;
        margin-top: 120px !important;
      }

      .three-and-four-step{
        display: block;
        width: unset;
        padding-left: 30px;
        margin-top: 50px !important;
      }
    
      .progress-div-create{
        margin-top: 40px;
        width: 300px;
        padding: 0px;
      }

    .exchange-partner-recipient-input-div{
        padding: 40px 15px;
    }

    .exchange-partner-recipient-input{
        width: 150px;
    }

    .exchange-partner-recipient-input> span{
        font-size: 13px;
      }
    
    .exchange-type-recipient-input> span{
        font-size: 13px;
    }

    .exchange-type-recipient-input> div{
        width: 150px;
        font-size: 15px;
        height: 40px;
      }


    .three-and-four-step{
        width: unset !important;
    }

    .recipient-get-coin-logos{
        width: 100px;
    }

    .recipient-send-amount-div{
        margin-left: 10px;
    }

    .recipient-get-amount-div{
        margin-right: 10px;
    }

    .recipient-send-coin-logos>img{
        width: 17px;
    }

    .recipient-send-coin-logos>:nth-child(2){
        font-size: 12px;
    }

    .recipient-get-coin-logos>:nth-child(2){
        font-size: 12px;
    }

    .recipient-get-coin-logos>img{
        width: 17px;
    }

    .chainname-deposit-page{
        font-size: 6px;
    }

    .three-and-four-step{
        width: unset;
    }
}

@media only screen and (max-width: 375px){

    .progress-div-create{
        width: 260px;
    }

    .progress-div-create-status> :nth-child(1) {
        margin-left: -8px;
        font-size: 9px;
    }
    .progress-div-create-status> :nth-child(2) {
        margin-left: -25px;
        font-size: 9px;
    }
    .progress-div-create-status> :nth-child(3) {
        margin-right: 6px;
        font-size: 9px;
    }
    .enter-address-text-div> :nth-child(1) {
        display: block;
        font-size: 28px;
    }
    .enter-address-text-div> :nth-child(2) {
        display: block;
        font-size: 28px;
    }
    .grey-line-div {
        width: 54px;
    }
    .exchange-partner-recipient-input {
        width: 185px;
        margin-left: 11px;
    }
    .exchange-partner-recipient-input> span {
        font-size: 12px;
    }
    .exchange-partner-recipient-input> img {
        width: 130px;
        margin-top: 15px;
    }
    .exchange-type-recipient-input> span {
        font-size: 12px;
    }
    .exchange-type-recipient-input> div {
        width: 130px;
    }
    .recipient-input-bullet-1 {
        font-size: 14px;
        padding-right: 20px;
    }
    .recipient-input-bullet-2>:nth-child(2) {
        padding-right: 20px;
    }
    .guide-div>div {
        padding-right: 20px;
    }
    .recipient-input-how-to {
        padding: 0px 30px;
    }
    .three-and-four-step {
        padding-left: 30px !important;
    }
}
@media only screen and (max-width: 320px){
    .progress-div-create-status> :nth-child(1) {
        margin-left: 2px;
        font-size: 12px;
    }
    .progress-div-create-status> :nth-child(2) {
        margin-left: -5px;
        font-size: 12px;
    }
    .progress-div-create-status> :nth-child(3) {
        margin-right: 6px;
        font-size: 12px;
    }
    .recipient-amount-div {
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .recipient-send-amount-div {
        text-align: center;
        margin-left: 5px;
    }
    .grey-line-div {
        width: 22px;
    }
    .recipient-get-amount-div {
        text-align: center;
        margin-right: 0px;
    }
    .exchange-partner-recipient-input {
        width: 130px;
        margin-left: 11px;
    }
}
