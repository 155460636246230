.enter-address-text-div {
    text-align: center;
    margin-top: 50px !important;
    margin-bottom: 60px;
}

.partner-image {
    width: 100%;
    height: auto;
}

.howitworks-link{
    color: #F0970D;
    text-decoration: none;
}

.howitworks-link:hover{
    color: #F0970D;
    text-decoration: none;
}
